import { getMainFooter } from 'data/page/utils';
import { getBrand } from 'data/page/utils/getBrand';
import NotFound from 'components/Pages/NotFound';
import type { ParsedUrlQuery } from 'querystring';
import type { GetStaticProps } from 'next';
import type { Brand } from 'types/Brand';
import type { FooterItemType } from 'types/Footer';

type Props = {
  brand: Brand | null;
  footer: FooterItemType;
};

interface Params extends ParsedUrlQuery {
  locale: string;
}

const page404 = ({ footer, brand }: Props): JSX.Element => {
  return <NotFound brand={brand} footer={footer} />;
};

export const getStaticProps: GetStaticProps<
  Record<string, unknown>,
  Params
> = async ({ params }) => {
  const { locale } = params || {};
  const brand = await getBrand(process.env.BRAND_ID || null, locale);

  const lang = locale || 'en';

  return {
    props: {
      brand,
      footer: getMainFooter(lang),
    },
  };
};

export default page404;
