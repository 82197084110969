import { QUNOMEDICAL_BRAND_ID } from 'constants/brand';
import clsx from 'clsx';
import GetInTouch from 'components/Shared/GetInTouch';
import Layout from 'components/ui/Layout';
import Link from 'components/ui/Link';
import { useTranslation } from 'hooks/useTranslation';
import styles from './NotFound.module.scss';
import type { Brand } from 'types/Brand';
import type { IFooter } from 'types/contentful/generated';
import type { FooterItemType } from 'types/Footer';

type Props = {
  brand: Brand | null;
  footer: IFooter | FooterItemType | null | undefined;
};

const NotFound = ({ brand, footer }: Props): JSX.Element => {
  const { t, locale } = useTranslation();
  const isQunomedical = brand?.sys.id === QUNOMEDICAL_BRAND_ID;

  const renderContent = (): JSX.Element => {
    const links = [
      { title: t('home'), url: '' },
      { title: t('header.howItWorks'), url: `/${t('header.howItWorks.link')}` },
      { title: t('header.about'), url: `/${t('header.about.link')}` },
      { title: 'blog', url: '/blog' },
      { title: t('footer.contactUs'), url: `/${t('footer.contactUs.link')}` },
    ];

    return (
      <>
        <div className={styles.content}>
          <p className={styles.text}>{t('pageNotFound.text')}</p>
          <p className={styles.text2}>{t('pageNotFound.text2')}</p>
        </div>
        <ul className={styles.nav}>
          {links.map((link, i) => (
            <li key={i} className={styles.navItem}>
              <Link
                href={`/${locale}${link.url}`}
                title={link.title}
                legacyBehavior={false}
              >
                {link.title}
              </Link>
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <Layout
      title={t('pageNotFound')}
      footer={footer}
      searchIndex={false}
      brand={brand}
    >
      <div className={styles.page}>
        <div className={styles.topBg} />
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.contentWrapper}>
              <h1 className={styles.title}>{t('pageNotFound')}</h1>
              <p className={styles.errorCode}>Error code: 404</p>
              {isQunomedical && (
                <>
                  {renderContent()}
                  <div
                    className={clsx(styles.bottomBgMobile, styles.bottomBg)}
                  />
                </>
              )}
            </div>
            {isQunomedical && (
              <div className={styles.getInTouch}>
                <GetInTouch verticalForm />
              </div>
            )}
          </div>
          {isQunomedical && (
            <div className={clsx(styles.bottomBgDesktop, styles.bottomBg)} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
